import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Parallax } from '../functional/Parallax';
import { ScrollTop } from '../functional/ScrollTop';
import { ContactFormPVModal } from '../functional/ContactFormPV';
import ScrollToTopOnMount from '../functional/scrollToTop';

import logo from '../img/NE-logo-plain.png';

const primary = '#2346A0';
const secondary = '#7ab340';

function Page(props) {
    const theme = useTheme();
    return (
        <Box sx={{ display: 'flex', justifyContent:'center', flexDirection:'column', width: '100%', paddingBottom: '20px' }}>
            <ScrollToTopOnMount />
            <Parallax height='100vh' bgimage={props.bg} icon={logo} iconDisplay={"none"} sectionTitle={props.title} color={props.color} topPos={props.topPos} />
            <Box sx={{ display: 'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', paddingTop:'5vh', }}>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0',
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p1}
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0',
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p2}
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0',
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p3}
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0', display: props.p4dis,
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p4}
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0', display: props.p5dis,
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p5}
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0', display: props.p6dis,
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p6}
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0', display: props.p7dis,
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p7}
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'1em', md:'1.1em', lg: '1.2em' }, color: primary, margin: '10px 0', display: props.p8dis,
                                        width: { xs:300, md:600, lg:800, xl:900 }, textAlign:{xs:'left',md:'justify'}, }} >
                    {props.p8}
                </Box>
                <Box component="div" sx={{ display: props.form, margin: '2vh 0 8vh 0' }} >
                    <ContactFormPVModal btnText={'Αποκτήστε Φ/Β Σύστημα NET METERING'} btnSize='large'
                                    bgcolor={theme.palette.primary.main} bgcolorhover={theme.palette.secondary.main} />
                </Box>
                <IconButton variant="outlined" onClick={() => {document.location.href = `/${props.back}`}}
                            sx={{ backgroundColor: secondary, color: '#000000', position:'fixed', bottom:'16px', left:'16px',
                                ":hover": { backgroundColor: primary, color: '#ffffff' }}} >
                    <ArrowBackOutlinedIcon sx={{opacity:'0.8'}} />
                </IconButton>
            </Box>

            <ScrollTop {...props}>
                <Fab color="secondary" size="small" aria-label="scroll back to top" sx={{":hover": { backgroundColor: primary, color: '#ffffff' } }} >
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </Box>
);}


export default Page
