import React from 'react';
import './App.css';
import { Routes, Route, useParams } from "react-router-dom";
import { Header } from './components/Header';
import Footer from './components/Footer';
// import { Main } from './components/Main';
import Sections from './components/sections';
// import VTabs from './components/structure/vTabs';
import Page from './components/structure/page';
import { pageData } from './components/text/pageText';
import { sectionData } from './components/text/sectionText';
import { createTheme, ThemeProvider, } from '@mui/material/styles';
import { green } from '@mui/material/colors';

import bg1 from './components/img/bg1.jpg';
import bg2 from './components/img/bg2b.jpg';
import bg3 from './components/img/bg3b.jpg';
import bg6 from './components/img/bg6.jpg';
import bg7 from './components/img/bg7.jpg';
import bg8 from './components/img/bg8.jpg';
import bg9 from './components/img/bg9.jpg';


const theme = createTheme({
  palette: {
    primary: {
      main: '#2346A0',
      dark: '#155475'
    },
    secondary: {
      main: '#7ab340',
      dark: green[900]
    },
  },
  components: {
      MuiTabs: {
          styleOverrides: {
            scroller: { backgroundColor: 'transparent', display: 'flex', alignItems: 'center', },
          },
      },
      MuiTooltip: {
          styleOverrides: {
              tooltip: { color: "#ffffff", backgroundColor: "#7ab340", fontSize: "1em", },
              arrow: { color: "#7ab340", },
           }
      },
      MuiFormControl: {
        styleOverrides: {
          root: { marginTop:'3px', marginBottom:'3px'}
        }
      }
  },
});

// const pathName = parseInt(window.location.pathname.slice(1));

function App() {
  const { tab } = useParams();
  console.log('tab:' + tab)
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
          <Header />
          <Routes>
            <Route path="/"            element={<Sections />} />
            {/*<Route path="/:active_tab"  element={<VTabs active={pathName ? pathName : 0} />} />*/}

            <Route path="company"      element={<Page txtMain={pageData[0].main} bg={bg1} form={'none'} back={''}
                                               title={`Ποιοι είμαστε`} color={'#fff'}
                                               p1={pageData[0].p1} p2={pageData[0].p2} p3={pageData[0].p3}
                                               p4={pageData[0].p4} p5={pageData[0].p5} p6={pageData[0].p6}
                                       />}
            />
            <Route path="net-metering" element={<Page txtMain={pageData[1].main} bg={bg2} title={`net-metering`}
                                                      form={'none'} back={'#Net-metering'} topPos={'12%'}
                                                      p1={pageData[1].p1} p2={pageData[1].p2} p3={pageData[1].p3} p4={pageData[1].p4}
                                                      color={theme.palette.secondary.main} p7dis={'none'} p8dis={'none'}
                                       />}
            />
            <Route path="saving"       element={<Page txtMain={pageData[2].main} bg={bg3} title={`Πόσο κερδίζετε;`} color={theme.palette.primary.main}
                                                       back={'#Saving'} form={'block'} topPos={'70%'}
                                                       p1={pageData[2].p1} p2={pageData[2].p2} p3={pageData[2].p3} p4={pageData[2].p4}
                                                       p5={pageData[2].p5} p6={pageData[2].p6} p7={pageData[2].p7} p8={pageData[2].p8}
                                       />}
            />
            <Route path="process"      element={<Page txtMain={pageData[3].main} bg={bg6} title={`H διαδικασία`} form={'block'}
                                                       back={'#Process'} color={'#fff'}
                                                       p1={pageData[3].p1} p2={pageData[3].p2} p3={pageData[3].p3}
                                                       p5dis={'none'} p6dis={'none'} p7dis={'none'} p8dis={'none'}
                                       />}
            />
            <Route path="StandAlone-PV" element={<Page txtMain={sectionData[6].mainText} bg={bg7} title={sectionData[6].head}
                                                   form={'none'} back={''} color={'#fff'} topPos={'50%'}
                                                   p1={sectionData[6].mainText} p2={''} p3={''} p4={``}
                                                   p5dis={'none'} p6dis={'none'} p7dis={'none'} p8dis={'none'}
                                        />}
            />
            <Route path="PV-Park" element={<Page txtMain={sectionData[7].mainText} bg={bg8} title={sectionData[7].head}
                                                   form={'none'} back={''} color={'#fff'}
                                                   p1={sectionData[7].mainText} p2={''} p3={''} p4={``}
                                                   p5dis={'none'} p6dis={'none'} p7dis={'none'} p8dis={'none'}
                                        />}
            />
            <Route path="Services" element={<Page txtMain={sectionData[8].mainText} bg={bg9} title={sectionData[8].head}
                                                   form={'none'} back={''} color={'#fff'}
                                                   p1={sectionData[8].mainText} p2={''} p3={''} p4={``}
                                                   p5dis={'none'} p6dis={'none'} p7dis={'none'} p8dis={'none'}
                                        />}
            />
            <Route path="Contact" element={<Footer />} />
          </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
