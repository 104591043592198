import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import FontAwesome from 'react-fontawesome';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {ContactFormModal} from './functional/ContactForm';
import NovoMap from './functional/map';
import bg from './img/bg10.jpg'
import logo from './img/NE-logo-w.png';
// import {ScrollTop} from './functional/ScrollTop';

const Footer = (props) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'space-between',
               width: '100%', height:'100%', backgroundSize:'cover', backgroundRepeat:'no-repeat',
               backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url(${bg})`,
           }}>
        <Box sx={{ display: 'flex', justifyContent:'center',  width: '100%', height:'100%', flexWrap:'wrap', alignItems:{xs:'end', md:'center'} }}>
            <Box sx={{ display: 'flex', flexBasis: {xs:'100%', md:'50%',lg:'40%'}, flexDirection:'column', justifyContent:'center', alignItems:'center',
                        transform:'translateY(4vh)'}}>
              <Container maxWidth="md" >
                  <img src={logo} className="logo-lg" alt="logo" />
              </Container>
              <Box sx={{display: 'flex',justifyContent:{xs:'center',md:'center'}, flexWrap:{xs:'wrap', sm:'nowrap'},}}>
                  <Toolbar position="static" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexBasis:{xs:'auto',sm:'70%'},
                                                   margin: {xs:'15px 0', md:'20px 0px'}, width:{xs:'60%',sm:'50%',md:'55%',lg:'50%'}  }}>
                     <Link href="https://goo.gl/maps/NzHY6vfqV852PVEW9" underline="hover" target="_blank" rel="noopener" color="secondary"
                           sx={{ width:'100%', margin: { xs:'5px', md:'10px'}, display:'flex', alignItems:'center', justifyContent:'flex-start',
                                 textDecoration:'none !important',}}>
                        <LocationOnIcon fontSize={'medium'} sx={{ flexBasis:'20%'}} />
                        <Typography component={'p'} sx={{ color: '#ffffff', fontSize:{xs:'1em'} }}>Ιονίου 3, Άλιμος, 17455</Typography>
                     </Link>
                     <Link href="mailto:info@novoenergy.gr" underline="hover" target="_blank" rel="noopener" color="secondary"
                           sx={{ width:'100%', margin: { xs:'5px', md:'10px'}, display:'flex', alignItems:'center', justifyContent:'flex-start',
                                 textDecoration:'none !important',}}>
                       <FontAwesome name='envelope' spin={false} className="icon" style={{ flexBasis:'20%', marginRight:'5px' }} />
                       <Typography component={'p'} sx={{ color: '#ffffff', fontSize:{xs:'1em'} }}>info@novoenergy.gr</Typography>
                     </Link>
                     <Link href="tel:+306944453813" underline="hover" target="_blank" rel="noopener" color="secondary"
                           sx={{ width:'100%', margin: { xs:'5px', md:'10px'}, display:'flex', alignItems:'center', justifyContent:'flex-start',
                                 textDecoration:'none !important',}}>
                       <CallIcon fontSize={'medium'} sx={{ flexBasis:'20%'}} />
                       <Typography component={'p'} sx={{ color: '#ffffff', fontSize:{xs:'1em'} }}>(+30) 210 220 7064</Typography>
                     </Link>
                     <Link href="https://www.facebook.com/NovoEnergyGR" underline="hover" target="_blank" rel="noopener" color="secondary"
                           sx={{ width:'100%', margin: { xs:'5px', md:'10px'}, display:'flex', alignItems:'center', justifyContent:'flex-start',
                                 textDecoration:'none !important',}}>
                       <FontAwesome name='facebook' spin={false} className="icon" style={{ flexBasis:'20%', }} />
                       <Typography component={'p'} sx={{ color: '#ffffff', fontSize:{xs:'1em'} }}>/NovoEnergyGR</Typography>
                     </Link>
                  </Toolbar>
                  <ContactFormModal btnText="ΕΠΙΚΟΙΝΩΝΗΣΤΕ ΜΑΖΙ ΜΑΣ" bgcolor={theme.palette.secondary.main} bgcolorhover={theme.palette.primary.main} />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexBasis: {xs:'100%', sm:'100%', md:'50%', lg:'60%'}, flexDirection:'column',
                       justifyContent:'center', alignItems:'center', padding:'10px 0px' }}>
                <NovoMap />
            </Box>
      </Box>
      <Box component="p" sx={{ textAlign: 'center', width:'100%', color: theme.palette.secondary.main }}>
        Novo Energy © 2022
      </Box>
    </Box>
  )
}

export default Footer;
