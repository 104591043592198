import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import bg4 from '../img/bg4.jpg';
import c1 from '../img/nm-app-1.jpg';
import c2 from '../img/nm-app-2.jpg';
import c3 from '../img/nm-app-3.jpg';
import c4 from '../img/nm-app-4.jpg';
import c5 from '../img/nm-app-5.jpg';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'Οικιακά συστήματα',
    imgPath: c1,
    text: `H τρέχουσα ενεργειακή κρίση έχει οδηγήσει στην αύξηση του λογαριασμού ρεύματος όλων των νοικοκυριών με αποτέλεσμα το net-metering αποτελεί
        ιδανική λύση για όλες τις κατοικίες. Αξίζει να σημειωθεί ότι υπάρχει δυνατότητα εγκατάστασης τόσο σε επίπεδα δώματα όσο και κεραμοσκεπές. Επίσης,
        τα Φ/Β συστήματα net metering συνδυάζονται ιδανικά με την εγκατάσταση αντλίας θερμότητας ή/και την αγορά ηλεκτρικού αυτοκίνητου που διπλασιάζουν
        την κατανάλωση μίας κατοικίας. To Φ/Β σύστημα μπορεί να συνδυαστεί με την αποθήκευση της παραγόμενης ενέργειας μέσω μπαταριών για μεγαλύτερη
        εξοικονόμηση λόγω μείωσης των ρυθμιζόμενων χρεώσεων. Σε αυτή την περίπτωση ένα ακόμα πλεονέκτημα είναι πως μπορεί να χρησιμοποιηθεί και ως
        εναλλακτική πηγή ενέργειας (backup) τροφοδοτώντας τα κρίσιμα φορτία της κατοικίας σας σε ενδεχόμενη διακοπή ρεύματος.`
  },
  {
    label: 'Βιομηχανικές και βιοτεχνικές μονάδες',
    imgPath: c2,
    text: `Οι βιομηχανικές και βιοτεχνικές μονάδες έχουν τεράστιο όφελος από το net-metering κι αυτό γιατί συνήθως έχουν υψηλή κατανάλωση ρεύματος το
        κόστος της οποίας μπορούν να μηδενίσουν μιας και οι στέγες τους προσφέρουν αρκετή επιφάνεια για την εγκατάσταση Φ/Β συστημάτων μεγάλης ισχύος
        ικανά να καλύψουν τις ενεργειακές τους ανάγκες. Επίσης, οι ώρες κατανάλωσης συνήθως συμπίπτουν με τις ώρες παραγωγής του Φ/Β με αποτέλεσμα να
        μην απαιτείται να απορροφούν ενέργεια από το Δίκτυο. Τέλος, οι χρεώσεις ΦΠΑ συμψηφίζονται, ενώ η συνολική δαπάνη της επένδυσης εκπίπτει του φόρου.`
  },
  {
    label: 'Ξενοδοχειακές μονάδες',
    imgPath: c3,
    text: `Οι ξενοδοχειακές μονάδες θα μπορούσαν να επωφεληθούν ιδιαιτέρως από το net-metering επειδή επί το πλείστον έχουν μεγαλύτερη ζήτηση σε ενέργεια
    τους θερινούς μήνες, που η διάρκεια της ημέρας και η ηλιοφάνεια είναι μεγαλύτερη. Από την άλλη, κατά τη διάρκεια του χειμώνα που δεν έχουν κατανάλωση
    μπορούν να αποθηκεύουν έμμεσα την παραγόμενη ενέργεια στο Δίκτυο για μεταγενέστερη χρήση. Στη περίπτωση μάλιστα που το Φ/Β σύστημα συνδυαστεί με
    αποθήκευση της παραγόμενης ενέργειας μέσω μπαταριών υπάρχει δυνατότητα να χρησιμοποιηθεί και ως εναλλακτική πηγή ενέργειας (backup) σε ενδεχόμενη
    διακοπή ρεύματος προσφέροντας μία επιπλέον υπηρεσία στους πελάτες τους.`
  },
  {
    label: 'Στέγαστρα οχημάτων',
    imgPath: c4,
    text: `Τα στέγαστρα των οχημάτων αποτελούν ανεκμετάλλευτη επιφάνεια ιδανική για την εγκατάσταση Φ/Β συστημάτων. Είτε πρόκειται για οικιακή κατασκευή
    είτε για μεγάλο ημιυπαίθριο χώρο στάθμευσης μπορούν να αξιοποιηθούν και να αποκομίσουν κέρδος μέσω του net-metering. Καθώς τα τελευταία χρόνια παρατηρούμε
    αύξηση της κυκλοφορίας ηλεκτρικών αυτοκινήτων, η τοποθέτηση ενός Φ/Β συστήματος net-metering στο στέγαστρο του οχήματος μπορεί να καλύψει την κατανάλωσή
    του, μηδενίζοντας με αυτόν τον τρόπο το κόστος χρήσης του.`
  },
  {
    label: 'Ειδικές κατασκευές',
    imgPath: c5,
    text: `Τα Φ/Β συστήματα net-metering αποτελούν μια συμφέρουσα επένδυση και η Novo Energy αναλαμβάνει τη μελέτη και υλοποίηση εξατομικευμένων κατασκευών.
    Επικοινωνήστε μαζί μας για να σας προτείνουμε λύσεις προσαρμοσμένες σε κάθε σας ανάγκη.`
  },
];

function AppDeck() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%', height:'100%', display: { xs: 'flex', sm: 'flex', md:'none' }, justifyContent:'center',
               backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url(${bg4})`,
               backgroundSize:'cover', backgroundRepeat:'no-repeat',
            }}>
      <Box sx={{ width: {xs:350, sm:550, md:800, lg:950, xl:950}, height: {xs:560, sm:560, md:600, lg:600, xl:700}, mt:1,
                 backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`, transform:'translateY(3vh)',
                 display:'flex', flexDirection:'column', justifyContent:'space-between', alignSelf:'center' }}>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            interval={6000}
            enableMouseEvents
            autoplay={false}
          >
            {images.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                    <Box sx={{ display:'flex', flexDirection:'column', justifyContent:'center', alignSelf:'center', }}>
                        <Box component="h3" sx={{fontSize:{ xs:'1em', md:'1.2em', lg: '1.3em'}, color:'#ffffff', textAlign: 'center',
                                                 backgroundColor: 'transparent', margin: '10px 0'}}>
                            {images[activeStep].label}
                        </Box>
                        <Box sx={{
                                height: {xs:160,sm:180,md:200, lg:250, xl:300},
                                backgroundImage: `url(${step.imgPath})`, backgroundSize:'cover', backgroundRepeat:'no-repeat',
                                backgroundPosition: 'center',
                                display: 'block',
                                overflow: 'hidden',
                                width: '100%',
                            }}
                            alt={step.label}
                        />
                        <Box component="p" sx={{ fontSize:{xs:'0.75em',sm:'0.9em', md:'1em', lg:'1em' }, color:'#ffffff', textAlign: 'left', padding:'5px 15px' }}>
                             {step.text}
                        </Box>
                    </Box>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static" variant="text"
            activeStep={activeStep}
            sx={{ backgroundColor:'transparent', color: '#ffffff'}}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}
                      sx={{color:'#ffffff'}}
              >
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft sx={{fontSize:'2em'}} />
                ) : (
                  <KeyboardArrowRight sx={{fontSize:'2em'}} />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}
                      sx={{color:'#ffffff'}}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight sx={{ fontSize:'2em'}} />
                ) : (
                  <KeyboardArrowLeft sx={{ fontSize:'2em'}} />
                )}
                Back
              </Button>
            }
          />
          </Box>
    </Box>
  );
}

export default AppDeck;
