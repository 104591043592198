const pageData = [
  {
    main: `Η NovoEnergy είναι μία ταχέως αναπτυσσόμενη εταιρεία στον τομέα των φωτοβολταϊκών συστημάτων. Η ομάδα μας αποτελείται από  μηχανικούς με διεθνή εμπειρία στον σχεδιασμό και την κατασκευή φωτοβολταϊκών πάρκων μεγάλης κλίμακας, όπως και στις κτιριακές ηλεκτρομηχανολογικές εγκαταστάσεις.`,
    p1: <span>Η <span className="novo">Novo</span> <span className="energy">Energy</span> είναι μία ταχέως αναπτυσσόμενη
              εταιρεία στον τομέα των φωτοβολταϊκών συστημάτων. Η ομάδα μας αποτελείται από  μηχανικούς με διεθνή εμπειρία στον σχεδιασμό
              και την κατασκευή φωτοβολταϊκών πάρκων μεγάλης κλίμακας, όπως και στις κτιριακές ηλεκτρομηχανολογικές εγκαταστάσεις.</span>,
    p2: `Η τρέχουσα ενεργειακή κρίση σε συνδυασμό με τις περιβαλλοντικές επιπτώσεις από την καύση υδρογονανθράκων μας οδήγησαν στο να ενώσουμε τις δυνάμεις μας ιδρύοντας τη Novo Energy.`,
    p3: `Με τις υπηρεσίες μας αντισταθμίζουμε δραστικά τις οικονομικές επιπτώσεις από την αύξηση των τιμών της ενέργειας συμβάλλοντας ταυτόχρονα στον ενεργειακό μετασχηματισμό και την απανθρακοποιήση για ένα  πράσινο και βιώσιμο μέλλον.`,
    p4: `Η Novo Energy καινοτομεί με ανταγωνιστικές λύσεις για φωτοβολταϊκά συστήματα με ενεργειακό συμψηφισμό (net metering) τόσο για οικιακές (δώματα, κεραμοσκεπές, πέργκολες, σκίαστρα κ.τ.λ.) όσο και για επαγγελματικές εφαρμογές (βιομηχανικές στέγες, ξενοδοχεία κ.τ.λ.).`,
    p5: `Η εταιρεία μας δίνει τη δυνατότητα σε όσους επιθυμούν ένα ολοκληρωμένο «πράσινο σπίτι» να έχουν πλήρη αυτονομία συνδυάζοντας φωτοβολταϊκά συστήματα παραγωγής ενέργειας με την αποθήκευσή της μέσω μπαταριών.`,
    p6: `Τέλος, από τις δραστηριότητες της Novo Energy δεν λείπουν τα φωτοβολταϊκά πάρκα που η μεγάλη τεχνογνωσία της ομάδας μας στον τομέα αυτό μας καθιστά ιδανικούς συνεργάτες.`,
    p7: <span>Στη <span className="novo">Novo</span> <span className="energy">Energy</span> θέτουμε στη διάθεσή σας την εμπειρία, τις γνώσεις και την εξειδίκευση των διπλωματούχων μηχανικών μας, για τη μελέτη και υλοποίηση κάθε φωτοβολταϊκού συστήματος με στόχο ένα καλύτερο αύριο για όλους μας.</span>
  },
  {
    main: `Ο ενεργειακός συμψηφισμός (γνωστός και με τον όρο net-metering) αποτελεί ένα παγκοσμίως διαδεδομένο σύστημα που δίνει τη δυνατότητα στον εκάστοτε καταναλωτή να μηδενίσει το κόστος της ενέργειας που χρησιμοποιεί. Στο net-metering ο καταναλωτής με τη χρήση ανανεώσιμων πηγών παράγει την ενέργεια που χρειάζεται, ενώ παράλληλα κάνει χρήση του Δικτύου για την έμμεση αποθήκευσή της.`,
    p1: `Ο ενεργειακός συμψηφισμός (γνωστός και με τον όρο net-metering) αποτελεί ένα παγκοσμίως διαδεδομένο σύστημα που δίνει τη δυνατότητα στον εκάστοτε καταναλωτή να μηδενίσει το κόστος της ενέργειας που χρησιμοποιεί. Στο net-metering ο καταναλωτής με τη χρήση ανανεώσιμων πηγών παράγει την ενέργεια που χρειάζεται, ενώ παράλληλα κάνει χρήση του Δικτύου για την έμμεση αποθήκευσή της.`,
    p2: `Ο καταναλωτής λοιπόν μπορεί να εγκαταστήσει ένα φωτοβολταϊκό (Φ/Β) σύστημα  με την παραγωγή του οποίου θα καλύπτει τις ανάγκες του σε ενέργεια κατά τη διάρκεια της ημέρας, ενώ το πλεόνασμα θα εξέρχεται προς το Δίκτυο. Κατά τη διάρκεια της νύχτας ή των ημερών χωρίς ηλιοφάνεια, δηλαδή όταν το Φ/Β σύστημα δεν θα παράγει ενέργεια, ο καταναλωτής θα ικανοποιεί τις ανάγκες του απορροφώντας ενέργεια από το Δίκτυο. Στη συνέχεια η απορροφώμενη από το Δίκτυο ενέργεια συμψηφίζεται με την εξερχόμενη και προκύπτει η ανάλογη χρέωση ή πίστωση. Με βάση τα παραπάνω γίνεται κατανοητό  ότι στο net-metering η παραγωγή της ενέργειας δεν είναι απαραίτητο να γίνεται ταυτόχρονα με την κατανάλωσή της.`,
    p3: `Η παράλληλη λειτουργία του Φ/Β συστήματος με το Δίκτυο υλοποιείται μέσω της υφιστάμενης παροχής της εγκατάστασης κατανάλωσης με την απλή προσθήκη ενός μετρητή διπλής κατεύθυνσης καταγραφής (εισερχόμενης και εξερχόμενης ενέργειας) και ενός πιστοποιημένου μετρητή καταγραφής της παραγόμενης ενέργειας.`,
    p4: `Τέλος, ο ενεργειακός συμψηφισμός απαιτεί υπογραφή σύμβασης μεταξύ του Προμηθευτή και του καταναλωτή - παραγωγού η οποία έχει διάρκεια ισχύος 25 έτη, με έναρξη την ημερομηνία ενεργοποίησης της σύνδεσης του Φ/Β συστήματος.`,
  },
  {
    main: `Με σωστή μελέτη και διαστασιολόγηση του φωτοβολταϊκού συστήματος net metering μπορείτε ακόμη και να μηδενίσετε τις ανταγωνιστικές χρεώσεις του λογαριασμού ρεύματος, ενώ παράλληλα μειώνετε αισθητά και τις ρυθμιζόμενες χρεώσεις. Στη Novo Energy σας εγγυόμαστε τη μέγιστη δυνατή εξοικονόμηση δεδομένου ότι η μελέτη πραγματοποιείται από έμπειρα και καταρτισμένα στελέχη και η εγκατάσταση υλοποιείται από εξειδικευμένα συνεργεία υπό την επίβλεψη διπλωματούχου μηχανικού.`,
    p1: `Για τον υπολογισμού του οικονομικού οφέλους από την εγκατάσταση ενός φωτοβολταϊκού (Φ/Β) συστήματος net-metering θα πρέπει πρώτα να γίνει κατανοητός ο τρόπος υπολογισμού των χρεώσεων, ο οποίος και αναλύεται παρακάτω.`,
    p2: `Σε κάθε εκκαθαριστικό λογαριασμό ρεύματος πραγματοποιείται συμψηφισμός της εισερχόμενης και εξερχόμενης από και προς το Δίκτυο ενέργειας. Για το ανταγωνιστικό σκέλος του τιμολογίου λοιπόν υπάρχει χρέωση μόνο για τη διαφορά μεταξύ της Απορροφώμενης και της Εξερχόμενης ενέργειας, εφόσον αυτή είναι θετική. Στην περίπτωση που η διαφορά είναι αρνητική τότε αυτή πιστώνεται στον επόμενο εκκαθαριστικό λογαριασμό ως πρόσθετη εξερχόμενη ενέργεια. Η τελική εκκαθάριση του ενεργειακού συμψηφισμού γίνεται ανά τριετία και τυχόν πλεόνασμα ενέργειας συμψηφίζεται με τις χρεώσεις προηγούμενων περιόδων (αν υπάρχουν), διαφορετικά δεν αποζημιώνεται`,
    p3: `Οι ρυθμιζόμενες χρεώσεις του λογαριασμού (ΕΤΜΕΑΡ, Χρέωση Χρήσης Συστήματος, Χρέωση Χρήσης Δικτύου, Υπηρεσίες Κοινής Ωφέλειας, λοιπές χρεώσεις) υπολογίζονται μόνο επί της απορροφώμενης από το Δίκτυο ενέργειας.`,
    p4: `Με βάση τα παραπάνω γίνεται κατανοητό ότι με το net-metering εξοικονομείτε  μέσω της μείωσης τόσο των ανταγωνιστικών όσο και των ρυθμιζόμενων χρεώσεων.`,
    p5: `Το τελικό ποσό εξοικονόμησης, καθώς και ο χρόνος απόσβεσης, εξαρτάται από πολλούς και σύνθετους παράγοντες. Προφανώς, όσο μεγαλύτερη κατανάλωση έχει κάποιος τόσο μεγαλύτερο είναι το όφελός του από την εγκατάσταση ενός Φ/Β συστήματος net-metering. Φυσικά, η τρέχουσα τιμή της κιλοβατώρας παίζει καθοριστικό ρόλο, ειδικά τώρα που ενεργειακή κρίση έχει σαν αποτέλεσμα την υπέρμετρη αύξησή της. Άλλος σημαντικός παράγοντας είναι οι κλιματολογικές συνθήκες της περιοχής που θα εγκατασταθεί το σύστημα αφού στις περιοχές με μεγαλύτερη ηλιοφάνεια η παραγωγή ενέργειας είναι μεγαλύτερη. Τέλος, κρίσιμη παράμετρο αποτελεί και το προφίλ του κάθε καταναλωτή κι αυτό γιατί όταν η παραγωγή γίνεται ταυτόχρονα με την κατανάλωση το οικονομικό όφελος είναι ακόμη μεγαλύτερο καθώς μειώνονται περαιτέρω οι ρυθμιζόμενες χρεώσεις.`,
    p6: `Από τα παραπάνω καθίσταται σαφές πως για τη μεγιστοποίηση του κέρδους από ένα Φ/Β σύστημα net-metering είναι πολύ σημαντική η σωστή μελέτη και διαστασιολόγηση ούτως ώστε η παραγόμενη ενέργεια να ισούται με την καταναλισκόμενη. Στην περίπτωση που το σύστημα παράγει λιγότερο, ο καταναλωτής - παραγωγός θα καλείται να πληρώνει ένα μέρος της ενέργειας που καταναλώνει, ενώ στην περίπτωση που το σύστημα είναι μεγαλύτερο, θα έχει επιβαρυνθεί με αυξημένο κόστος εγκατάστασης αλλά δε θα αποζημιώνεται για το πλεόνασμα ενέργειας.`,
    p7: `Στη Novo Energy σας εγγυόμαστε τη μέγιστη δυνατή εξοικονόμηση δεδομένου ότι η μελέτη πραγματοποιείται από έμπειρα και καταρτισμένα στελέχη, η εγκατάσταση υλοποιείται από εξειδικευμένα συνεργεία υπό την επίβλεψη διπλωματούχου μηχανικού, ενώ σας παρέχουμε και τη δυνατότητα παρακολούθησης του Φ/Β συστήματος μέσω τηλεμετρίας για την απρόσκοπτη λειτουργία του.`,
    p8: `Συμπληρώστε την παρακάτω φόρμα και εμείς θα επικοινωνήσουμε μαζί σας για να σας ενημερώσουμε για το οικονομικό όφελος που θα έχετε από την εγκατάσταση ενός Φ/Β σύστηματος net-metering.`
  },
  {
    main: `Ο ενεργειακός συμψηφισμός (γνωστός και με τον όρο net-metering) αποτελεί ένα παγκοσμίως διαδεδομένο σύστημα που δίνει τη δυνατότητα στον εκάστοτε καταναλωτή να μηδενίσει το κόστος της ενέργειας που χρησιμοποιεί. Στο net-metering ο καταναλωτής με τη χρήση ανανεώσιμων πηγών παράγει την ενέργεια που χρειάζεται, ενώ παράλληλα κάνει χρήση του Δικτύου για την έμμεση αποθήκευσή της.`,
    p1: `Η διαδικασία για να αποκτήσετε φωτοβολταϊκό σύστημα net – metering είναι απλή και συνοψίζεται στα εξής πέντε βήματα:`,
    p2: <ol>
            <li className='p-li'>Συμπληρώνετε την παρακάτω φόρμα για να έρθουμε σε επικοινωνία μαζί σας και να σας προτείνουμε την ιδανική λύση Φ/Β συστήματος για τις ανάγκες σας.</li>
            <li className='p-li'>Υποβάλουμε αίτηση στον ΔΕΔΔΗΕ για τη σύνδεση του Φ/Β συστήματος στο Δίκτυο και υπογράφετε τη Σύμβαση Σύνδεσης.</li>
            <li className='p-li'>Εκκινούμε τη διαδικασία εγκατάστασης του Φ/Β συστήματος.</li>
            <li className='p-li'>Υποβάλουμε αίτηση προς τον Πάροχο που έχετε επιλέξει για σύναψη Σύμβασης Ενεργειακού Συμψηφισμού.</li>
            <li className='p-li'>Με την ολοκλήρωση των εργασιών εγκατάστασης του Φ/Β συστήματος υποβάλουμε αίτηση ενεργοποίησης της σύνδεσης και η διαδικασία ολοκληρώνεται.</li>
        </ol>,
    p3: `Επικοινωνήστε τώρα μαζί μας για να απολαμβάνετε και εσείς σχεδόν δωρεάν ρεύμα ενώ ταυτόχρονα μειώνετε σημαντικά το ενεργειακό σας αποτύπωμα.`,
  },
];

export {pageData}
