import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { HashLink } from 'react-router-hash-link';

// import { useTheme } from '@mui/material/styles';
// import Tab from '@mui/material/Tab';
// import ListItem from '@mui/material/ListItem';

// function LinkTab(props) {
//   const theme = useTheme();
//   return (
//     <Tab
//       component="a"
//       sx={{ textTransform: 'unset', padding:{md:'2px 8px',lg:'2px 16px'}, letterSpacing:0,
//             fontSize: {md:'0.875rem',sm:'0.875rem',lg:'1rem',xl:'1.1rem'}, 
//             ":hover": { color: theme.palette.primary.main, opacity:'1', } ,
//             ":focus": { color: theme.palette.secondary.main, opacity:'1', } ,
//             ":selected": { color: theme.palette.primary.main, opacity:'1', } ,
//          }}
//       {...props}
//     />
//   );
// }



const menuTitles = ['linkLabels','Ποιοι είμαστε','Net metering','Εφαρμογές','Πόσο κερδίζετε','Πόσο κοστίζει',
                                 'Διαδικασία','Αυτόνομα Φ/Β','Φ/Β πάρκα','Υπηρεσίες','Επικοινωνία'];
const menuLinks = ['linkTitles','company','net-metering','apps','save','costs',
                   'process','StandAlone-PV','PV-Park','Services','contact'];

export default function Drawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 200 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
          {/*<ListItem key={'nav'} sx={{display:'flex',flexDirection:'column'}}>
            <LinkTab label="Τι είναι" href="/#Net-metering"/>
            <LinkTab label="Εφαρμογές" href="/#Applications"/>
            <LinkTab label="Πόσο κερδίζετε" href="/#Saving"/>
            <LinkTab label="Πόσο κοστίζει" href="/#Cost"/>
            <LinkTab label="Διαδικασία" href="/#Process"/>
          </ListItem>*/}
          <ListItemButton key={'1'} sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}}/></ListItemIcon>
            <a href={`/` + menuLinks[1]} className="menu-link">{menuTitles[1]}</a>          
          </ListItemButton>        
          <ListItemButton component={HashLink} to={'/#' + menuLinks[2]} key={'2'} className="menu-link" sx={{ margin: '10px 0px',}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}}/></ListItemIcon>
            {/*<a href={`/#` + menuLinks[2]} className="menu-link">{menuTitles[2]}</a>
            <HashLink smooth to={'/#' + menuLinks[2]} className="menu-link">{menuTitles[2]}</HashLink>*/}
            {menuTitles[2]}
          </ListItemButton>
          <ListItemButton component={HashLink} to={'/#' + menuLinks[3]} key={'3'} className="menu-link" sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}}/></ListItemIcon>
            {/*<a href={`/#` + menuLinks[3]} className="menu-link">{menuTitles[3]}</a>
            <HashLink smooth to={`/#` + menuLinks[3]} className="menu-link">{menuTitles[3]}</HashLink>*/}
            {menuTitles[3]}
          </ListItemButton>
          <ListItemButton component={HashLink} to={'/#' + menuLinks[4]} key={'4'} className="menu-link" sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}}/></ListItemIcon>
            {/*<a href={`/#` + menuLinks[4]} className="menu-link">{menuTitles[4]}</a>
            <HashLink smooth to={`/#` + menuLinks[4]} className="menu-link">{menuTitles[4]}</HashLink>*/}
            {menuTitles[4]}
          </ListItemButton>
          <ListItemButton component={HashLink} to={'/#' + menuLinks[5]} key={'5'} className="menu-link" sx={{ margin: '10px 0px' }} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}}/></ListItemIcon>
            {/*<a href={`/#` + menuLinks[5]} className="menu-link">{menuTitles[5]}</a>
            <HashLink smooth to={'/#' + menuLinks[5]} className="menu-link">{menuTitles[5]}</HashLink>*/}
            {menuTitles[5]}
          </ListItemButton>
          <ListItemButton key={'6'} className="menu-link" sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}}/></ListItemIcon>
            <a href={`/` + menuLinks[6]} className="menu-link">{menuTitles[6]}</a>
          </ListItemButton>
          <ListItemButton key={'7'} className="menu-link" sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}}/></ListItemIcon>
            <a href={`/` + menuLinks[7]} className="menu-link">{menuTitles[7]}</a>
          </ListItemButton>
          <ListItemButton key={'8'} className="menu-link" sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}} /></ListItemIcon>
            <a href={`/` + menuLinks[8]} className="menu-link">{menuTitles[8]}</a>
          </ListItemButton>
          <ListItemButton key={'9'} className="menu-link" sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}} /></ListItemIcon>
            <a href={`/` + menuLinks[9]} className="menu-link">{menuTitles[9]}</a>
          </ListItemButton>
          <ListItemButton component={HashLink} to={'/#' + menuLinks[10]} key={'10'} className="menu-link" sx={{ margin: '10px 0px'}} >
            <ListItemIcon sx={{ flexBasis:'10%', minWidth:'20px' }}><ArrowForwardIosSharpIcon sx={{fontSize:'1em'}} /></ListItemIcon>
            {/*<a href={`/#` + menuLinks[10]} className="menu-link">{menuTitles[10]}</a>
            <HashLink smooth to={`/#` + menuLinks[10]} className="menu-link">{menuTitles[10]}</HashLink>*/}
            {menuTitles[10]}
          </ListItemButton>
      </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={toggleDrawer(anchor, true)}
            color="primary"
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
