import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import { ContactFormPVModal } from '../functional/ContactFormPV';

// const primary = '#2346A0';
// const secondary = '#7ab340';

function Section(props) {
      const theme = useTheme();
      return (
            <Box sx={{ display: 'flex', justifyContent:'center',  width: '100%', height: props.height,
                  backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center',
                  backgroundImage: {xs:`linear-gradient(-8deg, transparent 14%, rgba(255, 255, 255, 0.65) 18% 100%), url(${props.bg})`,
                                    md:`linear-gradient(-65deg, transparent 32%, rgba(255, 255, 255, 0.01) 28% 32%, rgba(255, 255, 255, 0.75) 45% 100%), url(${props.bg})`}
                  }}>
                        
                  <Box sx={{ display: 'flex', flexDirection:'column', flexBasis: {xs:'100%', md:'65%'}, justifyContent:'center', alignItems:'center' }}>
                        <Box component="h2" sx={{ fontSize:{xs:'1em', md:'1.3em', lg: '1.4em', xl:'1.5em' }, color: theme.palette.primary.main, 
                                                      textAlign: 'left', width: { xs:'90%', md:'80%', lg:'75%', xl:'70%' }, }} >
                              {props.head}
                        </Box>
                        <Box component="div" sx={{ fontSize:{xs:'0.9em', md:'1em', lg: '1.1em', xl:'1.2em' }, color: theme.palette.primary.main, margin:0,
                                                      fontWeight:{xs:'400', md:'400'}, width: { xs:'90%', md:'80%', lg:'75%', xl:'70%' },
                                                      textAlign:{xs:'left',md:'justify'}, }} >
                              {props.text}             
                        </Box>
                        <Button size="large" to={props.linkTo} component={Link}
                                    sx={{ backgroundColor: theme.palette.secondary.main, color: '#ffffff', display: props.haveBtn, margin:'15px 0',
                                          '&:hover':{ backgroundColor: theme.palette.primary.main }}}>
                              {props.btn}
                        </Button>
                        <Box component="div" sx={{ display: props.form, margin: '2vh 0 8vh 0' }} >
                              <ContactFormPVModal btnText={'Αποκτήστε Φ/Β Σύστημα NET METERING'} btnSize='large'
                                                bgcolor={theme.palette.secondary.main} bgcolorhover={theme.palette.primary.main} />
                        </Box>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection:'column', flexBasis: {xs:'0%', md:'35%'}, justifyContent:'center', alignItems:'center' }}>

                  </Box>
            </Box>
);}

export default Section
