import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import logo from './img/NE-logo-h.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
// import { Link } from 'react-router-dom';
import NavTabs from './functional/navTabs';
import Drawer from './structure/Drawer';
// import {ContactFormModal} from './functional/ContactForm';
const bg = '#ffffff';

function MenuAppBar() {
  const theme = useTheme();
  const styles = { display:'flex', justifyContent:'center', alignItems:'center' }
  return (
    <Box sx={{ flexGrow: 1, width:'100%' }} >
      <AppBar position="fixed" sx= {{ backgroundColor: bg }} >
        <Toolbar sx={{display:'flex', justifyContent:'space-between', alignItems:'center', minHeight:{xs:'45px',md:'56px'}}}>
          <a href='/' style={styles}>
            <img src={logo} className="logo-bar" alt="logo" />
          </a>
          <Box sx={{ display: { xs: 'none', sm: 'none', md:'flex' }, flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
              <NavTabs />
              {/*<ContactFormModal btnText='ΕΠΙΚΟΙΝΩΝΙΑ' btnSize='small' bgcolor={theme.palette.primary.main} bgcolorhover={theme.palette.secondary.main} />*/}
          </Box>
          <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' }, flexDirection:'row', justifyContent:'flex-end', alignItems:'center'}}>
              {/*<ContactFormModal btnText='ΕΠΙΚΟΙΝΩΝΙΑ' btnSize='small' bgcolor={theme.palette.primary.main} bgcolorhover={theme.palette.secondary.main} />*/}
              <Button href="/#contact" size='small' sx={{ textTransform: 'unset', backgroundColor:theme.palette.primary.main, color:'#ffffff', alignSelf:'center',
                            height:'30px', padding:'4px 5px', ":hover": { backgroundColor: theme.palette.secondary.main} }} >ΕΠΙΚΟΙΝΩΝΙΑ</Button>
              <Drawer />
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: '0px !important', backgroundColor: 'transparent' }} />
    </Box>
  );
}

const Header = () => {
  return (
    <div className="App-header">
      <MenuAppBar />
    </div>
  )
}

export {Header}
