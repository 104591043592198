import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function Parallax(props) {
  const id = props.id;
  const bgimage = props.bgimage;
  const icon = props.icon;
  const iconDisplay = props.iconDisplay;
  const height = props.height;
  const style = {
    minHeight: height,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRrepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'url(' + bgimage + ')',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };
  const styleTitle = {
    fontWeight: '700',
    letterSpacing: '0.4rem',
    textShadow: '1px 1px 1px #666',
    color: props.color,
    position: 'absolute',
    left: '50%',
    transform: 'translatex(-50%)',
    top: props.topPos,
    width: '90%',
  };
  const styleImg = {
    alignSelf: 'center',
    display: iconDisplay,
    width: '100px'
  };
  return (
    <div className="parallax-container" style={style} id={id}>
        <img className="logo-pl" src={icon} style={styleImg} alt="logo" />
        <div className="parallax-title" style={styleTitle}>{props.sectionTitle}</div>
        <KeyboardArrowDownIcon sx={{position:'absolute', bottom:{xs:'15vh',md:'3vh'}, left:'50%', opacity:'0.8', fontSize:'3em',
                                    transform:'translate(-50%)', color:props.color,
                                    animationName:'bounce-once', animationTimingFunction:'ease-in', animationDuration:'2s', animationIterationCount:'infinite',
                                  }} />
    </div>
  )
}

export {Parallax}
