import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import ThreeSixtyIcon from '@mui/icons-material/ThreeSixty';
import { CardActionArea } from '@mui/material';
import ReactCardFlip from 'react-card-flip';

export default function Application(props) {
  const [flip, setFlip] = React.useState(false);

  return (
    <Card sx={{maxWidth: 400, width: {xs:'100%',md:210,lg:330,xl:400}, height:{xs:120,md:520,lg:490,xl:480}, backgroundColor: 'transparent',
               margin: '5px 5px', transform: 'translateY(6vh)' }}>
        <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
            <CardActionArea className="front" onClick={() => setFlip(!flip)}>
                <Box sx={{ display: 'flex', justifyContent:'space-around', flexDirection:'column', alignItems:'center', width: '100%',
                           backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:props.bgPosition,
                           height:{xs:120,md:520,lg:490,xl:480},
                           backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.1) ), url(${props.imgfile})`,
                }}>
                    <CardHeader title={props.title} sx={{ color: '#ffffff' }} />
                    <ThreeSixtyIcon sx={{ color: '#ffffff'}} />
               </Box>
            </CardActionArea>
            <CardActionArea className="back" onClick={() => setFlip(!flip)} sx={{  }}>
                <Box sx={{ width:'100%', height:90, display:{xs:'none', md:'flex'}, justifyContent:'center',  flexDirection:'column',
                           backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center',
                           backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.4) 20%, rgba(0, 0, 0, 0.2) ), url(${props.imgfile})`,
                }}>
                    <Typography component={'p'} sx={{ color: '#ffffff', fontSize: {xs:'1.2em', md:'1.3em',lg:'1.5em' } }}>
                        {props.title}
                    </Typography>
                </Box>
                <Box component="p" sx={{ fontSize:{xs:'0.9em', md:'0.9em', lg:'1em', xl:'1.1em' }, fontFamily:"Roboto", color:'#ffffff',
                                         textAlign: 'left', padding:{md:'7px 5px',lg:'10px 15px'}, margin:0,
                                         backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center',
                                         backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0))`,
                                       }}>
                     {props.text}
                </Box>
            </CardActionArea>
      </ReactCardFlip>
    </Card>
  );
}
