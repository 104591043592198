import * as React from 'react';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';

const primary = '#2346A0';
const secondary = '#7ab340';

function Intro(props) {
  return (
      <Box sx={{ display: 'flex', justifyContent:'center',  width: '100%', height: '100%', backgroundSize:'cover', backgroundRepeat:'no-repeat',
                 backgroundPosition: {xs:'60% 25%',sm:'center'}, backgroundImage: {xs:`url(${props.bg})`},
              }}>
               {/*sx={{ display: 'flex', justifyContent:'center',  width: '100%', height: '100%',
                 backgroundSize:'cover', backgroundRepeat:'no-repeat',
                 backgroundPosition: {xs:'right',sm:'center'},
                 backgroundImage: {xs:`linear-gradient(-45deg, transparent 0% 50%, rgba(0,0,0,0.1) 51% 51.1%, rgba(255, 255, 255, 0.8) 51% 100% ), url(${props.bg})`,
                                   md:`linear-gradient(-45deg, transparent 0% 54%, rgba(0,0,0,0.1) 55% 55.1%, rgba(255, 255, 255, 0.7) 55% 100% ), url(${props.bg})`,},
               }}>*/}
          <Box sx={{ display: 'flex', flexDirection:'column', flexBasis: {xs:'100%', md:'60%'}, marginLeft:{xs:'0', sm:'5vw'},
                   marginTop:{xs:'11vh',sm:'20vh'}, justifyContent:'flex-start', alignItems:{xs:'center',sm:'start'}, }}>          
               <Fade in={props.inView} timeout={1000}>
                    <img src={props.head} className='logo-intro' alt='Novo Energy' />
               </Fade>
               <Box component="p" sx={{ fontSize:{xs:'1.2em',sm:'1.4em', md:'1.5em', lg:'1.7em', xl:'2em' }, color:secondary, textAlign: {xs:'center',sm:'left'}, fontWeight:700,
                                        width: { xs:'100%',sm:400,md:450,lg:600,xl:650 }, margin:{xs:'10px 0',sm:'10px 0'}}}>
                    <Grow in={props.inView} timeout={1500}>{props.text}</Grow>
               </Box>
               <Box component="p" sx={{ fontSize:{xs:'1em', md:'1em', lg:'1.3em', xl:'1.5em' }, color:primary, textAlign: {xs:'center',sm:'left'}, fontWeight:700,
                                        width: { xs:'80%',sm:400,md:450,lg:600,xl:650 }, margin:{xs:'5px 0',sm:'0'}}}>
                    <Grow in={props.inView} timeout={1500}>{props.subtext}</Grow>
               </Box>
               <Fade in={props.inView} timeout={2000}>
                    <Button size="large" component="a" href={props.linkTo} 
                         sx={{ backgroundColor: secondary, color: '#ffffff', display: props.haveBtn, margin:'15px 0', fontSize:{xs:'1em', md:'1.1em', lg:'1.3em', xl:'1.5em' },
                              '&:hover':{ backgroundColor: primary }}}>
                    {props.btn}
                    </Button>
               </Fade>          
          </Box>
          <Box sx={{ display: 'flex', flexDirection:'column', flexBasis: {xs:'0%', md:'40%'}, justifyContent:'center', alignItems:'center' }}>

          </Box>
      </Box>
);}

export default Intro



