const sectionData = [
    {
      head: <span><span className="novo">Novo</span> <span className="energy">Energy</span></span>,
      tooltip: `Novo Energy`,
      link: ``,
      mainText: <span>ΝΕΑ ΕΠΟΧΗ ΣΤΗΝ ΕΝΕΡΓΕΙΑ</span>,
      subText: <span>Ολοκληρωμένες λύσεις για την ενεργειακή ανεξαρτησία κάθε νοικοκυριού και επιχείρησης με την εγγύηση ποιότητας της Novo Energy</span>
    },
    // {
    //   head: `Ποιοι είμαστε`,
    //   tooltip: `Ποιοι είμαστε`,
    //   link: `Company`,
    //   mainText: <span>Η Novo Energy είναι μία ταχέως αναπτυσσόμενη εταιρεία στον τομέα των φωτοβολταϊκών συστημάτων.
    //              Η ομάδα μας αποτελείται από  μηχανικούς με διεθνή εμπειρία στον σχεδιασμό και την κατασκευή φωτοβολταϊκών πάρκων
    //              μεγάλης κλίμακας, όπως και στις κτιριακές ηλεκτρομηχανολογικές εγκαταστάσεις.</span>,
    // },
    {
      head: `Net metering: Με την ηλιακή ενέργεια μπορείτε να μηδενίσετε τους λογαριασμούς ρεύματος`,
      tooltip: `Net metering`,
      link: `Net-metering`,
      moblink:"net-metering",
      mainText: <span>Ο ενεργειακός συμψηφισμός (γνωστός και με τον όρο net-metering) αποτελεί ένα παγκοσμίως διαδεδομένο σύστημα που
                 δίνει τη δυνατότητα στον εκάστοτε καταναλωτή να μηδενίσει το κόστος της ενέργειας που χρησιμοποιεί. Στο net-metering
                 ο καταναλωτής με τη χρήση ανανεώσιμων πηγών παράγει την ενέργεια που χρειάζεται, ενώ παράλληλα κάνει χρήση του
                 Δικτύου για την έμμεση αποθήκευσή της.</span>,
    },
    {
      head: `Εφαρμογές`,
      tooltip: `Εφαρμογές`,
      link: `Applications`,
      moblink: "apps",
    },
    {
      head: `Πόσο κερδίζετε: Αντιμετωπίστε την αύξηση στην τιμή της ενέργειας με τη βοήθεια του ήλιου`,
      tooltip: `Πόσο κερδίζετε`,
      link: `Saving`,
      moblink: "save",
      mainText: <span>Με σωστή μελέτη και διαστασιολόγηση του φωτοβολταϊκού συστήματος net metering μπορείτε ακόμη και να μηδενίσετε τις
                 ανταγωνιστικές χρεώσεις του λογαριασμού ρεύματος, ενώ παράλληλα μειώνετε αισθητά και τις ρυθμιζόμενες χρεώσεις. Στη
                 Novo Energy σας εγγυόμαστε τη μέγιστη δυνατή εξοικονόμηση δεδομένου ότι η μελέτη πραγματοποιείται από έμπειρα και
                 καταρτισμένα στελέχη και η εγκατάσταση υλοποιείται από εξειδικευμένα συνεργεία υπό την επίβλεψη διπλωματούχου μηχανικού.</span>,
    },
    {
      head: `Πόσο κοστίζει και πότε θα κάνετε απόσβεση`,
      tooltip: `Πόσο κοστίζει`,
      link: `Cost`,
      moblink: "costs",
      mainText: <span>Το κόστος και ο χρόνος απόσβεσης για ένα Φ/Β σύστημα net-metering εξαρτάται κατά κύριο λόγο από την ισχύ που αποδίδει, δηλαδή από την
          ποσότητα της ηλεκτρικής ενέργειας που παράγει. Ωστόσο, η αύξηση της ισχύος δεν είναι αναλογική με την αύξηση του κόστους, με τα μεγαλύτερα συστήματα
          να έχουν καλύτερη σχέση τιμής - απόδοσης. Σημαντική παράμετρος επηρεασμού του κόστους είναι η ύπαρξη ή όχι αποθήκευσης (μπαταρίας).<br/><br/>Άλλοι
          παράγοντες που αυξάνουν την τιμή του συστήματος αλλά ταυτόχρονα και την αξιοπιστία του είναι η ποιότητα του βασικού εξοπλισμού και των λοιπών υλικών,
          η άρτια μελέτη καθώς και η σωστή εγκατάσταση από πιστοποιημένα συνεργεία. Στη Novo Energy προσφέρουμε ιδιαίτερα ανταγωνιστικές τιμές χωρίς όμως
          «εκπτώσεις» σε όλες αυτές τις παραμέτρους που είναι κρίσιμες για την αξιοπιστία του.</span>,
    },
    {
      head: `Διαδικασία: Απαλλαγείτε από το άγχος των χρεώσεων του ηλεκτρικού ρεύματος σε πέντε απλά βήματα`,
      tooltip: `Διαδικασία`,
      link: `Process`,
      moblink: "process",
      mainText: 
                <ol className='p-ol'>
                  <li className='p-li'>Συμπληρώνετε την παρακάτω φόρμα για να έρθουμε σε επικοινωνία μαζί σας και να σας προτείνουμε την ιδανική λύση Φ/Β συστήματος για τις ανάγκες σας.</li>
                  <li className='p-li'>Υποβάλουμε αίτηση στον ΔΕΔΔΗΕ για τη σύνδεση του Φ/Β συστήματος στο Δίκτυο και υπογράφετε τη Σύμβαση Σύνδεσης.</li>
                  <li className='p-li'>Εκκινούμε τη διαδικασία εγκατάστασης του Φ/Β συστήματος.</li>
                  <li className='p-li'>Υποβάλουμε αίτηση προς τον Πάροχο που έχετε επιλέξει για σύναψη Σύμβασης Ενεργειακού Συμψηφισμού.</li>
                  <li className='p-li'>Με την ολοκλήρωση των εργασιών εγκατάστασης του Φ/Β συστήματος υποβάλουμε αίτηση ενεργοποίησης της σύνδεσης και η διαδικασία ολοκληρώνεται.</li>
                </ol>,
    },
    {
      head: `Αυτόνομα φωτοβολταϊκά συστήματα`,
      tooltip: `Αυτόνομα Φ/Β`,
      link: `StandAlone-PV`,
      mainText: <span>Σε περιπτώσεις απομακρυσμένων κατοικιών που δεν υπάρχει δυνατότητα διασύνδεσης με το δίκτυο ή το κόστος σύνδεσης είναι απαγορευτικό τα
          αυτόνομα Φ/Β συστήματα αποτελούν ιδανική λύση. Το ίδιο ισχύει και για όσους επιθυμούν πλήρη αυτονομία ή ένα «πράσινο σπίτι».<br/><br/>Για να επιτευχθεί
          πλήρης αυτονομία συνδυάζεται ένα Φ/Β σύστημα παραγωγής ενέργειας με ένα σύστημα αποθήκευσης μέσω μπαταριών. Έτσι κατά τη διάρκεια της ημέρας ένα μέρος
          της ενέργειας που παράγει το Φ/Β καταναλώνεται απευθείας, ενώ το πλεόνασμα αποθηκεύεται στις μπαταρίες για χρήση κατά τη διάρκεια της νύχτας ή των ημερών
          που δεν υπάρχει ηλιοφάνεια.<br/><br/>Ωστόσο, στην περίπτωση του αυτόνομου Φ/Β συστήματος για να μην υπάρχει κίνδυνος έλλειψης ηλεκτρικής ενέργειας είναι
          ζωτικής σημασίας μια άρτια και επιστημονική μελέτη και διαστασιολόγησή του. Στη Novo Energy το έμπειρο και καταρτισμένο προσωπικό που αποτελείται
          αποκλειστικά από διπλωματούχους μηχανικούς σας εγγυάται ένα ιδανικό αποτέλεσμα.</span>,
    },
    {
      head: <span>Φωτοβολταϊκά πάρκα: <br/>Μια συμφέρουσα πράσινη επένδυση</span>,
      tooltip: `Φ/Β πάρκα`,
      link: `PV-Park`,
      mainText: <span>Οι επιπτώσεις της καύσης υδρογονανθράκων στην κλιματική αλλαγή έχουν οδηγήσει την παγκόσμια κοινότητα να στραφεί προς τις ανανεώσιμες πηγές
          ενέργειας όπως είναι τα αιολικά και τα φωτοβολταϊκά πάρκα. Τα Φ/Β πάρκα τις τελευταίες δεκαετίες συνδράμουν σημαντικά στην απεξάρτηση από τα ορυκτά
          καύσιμα για ένα «πράσινο μέλλον» αλλά ταυτόχρονα αποτελούν μία αξιόπιστη και κερδοφόρα επένδυση.<br/><br/> Στη Novo Energy αναλαμβάνουμε με υπευθυνότητα
          και τεχνογνωσία την μελέτη, αδειοδότηση, εγκατάσταση και θέση σε λειτουργία Φ/Β πάρκων πανελλαδικά.</span>,
    },
    {
      head: <span>Υπηρεσίες παρακολούθησης<br/>και συντήρησης</span>,
      tooltip: `Υπηρεσίες`,
      link: `Services`,
      mainText: <span>Στη Novo Energy εκτός από την κατασκευή Φ/Β συστημάτων παραγωγής ενέργειας προσφέρουμε και εκτεταμένες υπηρεσίες συνεχούς παρακολούθησης
          της απόδοσής του μέσω τηλεμετρίας. Με αυτόν τον τρόπο εντοπίζουμε και επιλύουμε πιθανά προβλήματα και διασφαλίζουμε την απρόσκοπτη λειτουργία της
          επένδυσής σας.<br/><br/>Επιπρόσθετα, διαθέτουμε πακέτα περιοδικής συντήρησης για τον επιτόπιο έλεγχο των Φ/Β πλαισίων, του inverter, των στηριγμάτων
          και των καλωδιώσεων προλαμβάνοντας το οποιοδήποτε πρόβλημα πριν να επηρεάσει την απόδοση και την ασφάλεια του συστήματος.</span>,
    },
]

export {sectionData}
