import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';



function LinkTab(props) {
  const theme = useTheme();
  return (
    <Tab
      component="a"
      sx={{ textTransform: 'unset', padding:{md:'2px 8px',lg:'2px 16px'}, letterSpacing:0,
            fontSize: {md:'0.875rem',sm:'0.875rem',lg:'1rem',xl:'1.1rem'}, 
            ":hover": { color: theme.palette.primary.main, opacity:'1', } ,
            ":focus": { color: theme.palette.secondary.main, opacity:'1', } ,
            ":selected": { color: theme.palette.primary.main, opacity:'1', } ,
         }}
      {...props}
    />
  );
}

export default function NavTabs() {
  const theme = useTheme();
  const [value, setValue] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [expanded, setExpanded] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setExpanded(!expanded);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ width: '100%', display: {xs:'none',md:'flex'} }}>
      <Tabs value={value} onChange={handleChange} TabIndicatorProps={{ style: {display:'none'} }} aria-label="desktop navigation">
        <LinkTab label="H εταιρεία" href="/company" />
        <Tab  label="Net metering" component="button" onClick={handleClick} iconPosition="end"
              sx={{ textTransform: 'unset', padding:'2px', fontSize: {md:'0.875rem',sm:'0.875rem',lg:'1rem',xl:'1.1rem'} }}
              icon={<IconButton onClick={handleClick} sx={{ padding:'1px', marginLeft:'1px !important' }}>
                      <ExpandMoreIcon sx={{color:theme.palette.secondary.main, transform:(expanded) ? 'rotate(180deg)' : undefined}} />
                    </IconButton>}  />
        <LinkTab label="Αυτόνομα συστήματα" href="/StandAlone-PV" />
        <LinkTab label="Φ/Β πάρκα" href="/PV-Park" />
        <LinkTab label="Υπηρεσίες" href="/Services" />
      </Tabs>
      <Button href="/#Contact" size='small' sx={{ textTransform: 'unset', backgroundColor:theme.palette.primary.main, color:'#ffffff', alignSelf:'center',
                    height:'40px', padding:'4px 5px', ":hover": { backgroundColor: theme.palette.secondary.main} }} >ΕΠΙΚΟΙΝΩΝΙΑ</Button>
      <Menu
        id="net-metering-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ top:'5px' }}
        MenuListProps={{ 'aria-labelledby': 'basic-btn', }}
      >
        <LinkTab label="Τι είναι" href="/#Net-metering" onClick={handleClose}/>
        <LinkTab label="Εφαρμογές" href="/#Applications" onClick={handleClose}/>
        <LinkTab label="Πόσο κερδίζετε" href="/#Saving" onClick={handleClose}/>
        <LinkTab label="Πόσο κοστίζει" href="/#Cost" onClick={handleClose}/>
        <LinkTab label="Διαδικασία" href="/#Process" onClick={handleClose}/>
      </Menu>
    </Box>
  );
}
