import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useForm } from "react-hook-form";
import Modal from '@mui/material/Modal';

import emailjs from 'emailjs-com';

const primary = '#2346A0';
const secondary = '#7ab340';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {xs:'80vw', sm:'90vw', md:'70vw', lg:'60vw', xl: '50vw'},
  bgcolor: 'background.paper',
  border: '5px solid #2346A0',
  borderRadius: '15px',
  padding: '1vmin 10px',
  boxShadow: 24,
  display: 'flex', flexDirection: 'column', alignItems: 'center',
};

function ContactFormPVModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data =>  {
      console.log(data);
      console.log(name,email,message);
      setBtntext('Sending...');
      emailjs.send('service_slyku8m','nepv_ulteuoc', templateParams, 'rJF1OpN_e7y2LQA0s')
      .then((response) => {
        showMsg();
        setBtntext('ΕΣΤΑΛΗ!');
        setTimeout(() => { resetForm(); }, 6000)
        console.log('SUCCESS!', response.status, response.text);
      }, (err) => {
        setBtntext('ΑΠΟΤΥΧΙΑ ΑΠΟΣΤΟΛΗΣ');
         console.log('FAILED...', err);
      });
  };
  const [btntext, setBtntext] = React.useState('ΑΠΟΣΤΟΛΗ');
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [tel, setTel] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [kwh, setKwh] = React.useState('');
  const [m2, setM2] = React.useState('');
  const [type, setType] = React.useState('');
  const [address, setAddress] = React.useState('');
  const templateParams = {
      name: name,
      email: email,
      subject: subject,
      message: message,
      tel: tel,
      kwh: kwh,
      m2: m2,
      type: type,
      address: address,  
  };
  const resetForm = () => { setName(''); setEmail(''); setSubject(''); setMessage(''); setTel(''); setBtntext('Αποστολή'); setStatus(''); 
                            setKwh(''); setM2(''); setType(''); setAddress('') }
  const showMsg = () => { setStatus('Ευχαριστούμε για το μήνυμα σας!')}
  return (
    <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center'}}>
      <Button onClick={handleOpen} size={props.btnSize} sx={{ backgroundColor: props.bgcolor, color: '#ffffff', textTransform:'none',
                                                              '&:hover':{backgroundColor: props.bgcolorhover}}}>
        {props.btnText}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
          <Box
            component="form"
            sx={style}
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex-row">
              <div className="txt-n-error">
                  <TextField fullWidth required id="form-name" label="Ονοματεπώνυμο" variant="outlined" sx={{ backgroundColor: '#ffffff',}} 
                         {...register("Name", { required: true, minLength: 5 })}
                         value={name} onChange={e => setName(e.target.value)} margin="dense" size="small"/> 
                  {errors.Name && <p className='form-error'>Απαραίτητο πεδίο</p>}
              </div>
              <div className="separator"></div>
              <div className="txt-n-error">
                  <TextField fullWidth required id="form-email" label="Email" variant="outlined"
                         {...register("Email", { required: true, pattern: /^[^@\s]+@[^@\s]+\.[^@\s]+$/ })}
                         value={email} onChange={e => setEmail(e.target.value)} margin="dense" size="small"/>
                  {errors.Email && <p className='form-error'>Η email διεύθυνση δεν είναι έγκυρη</p>}
              </div>          
            </div>        
            <div className="txt-n-error">
              <TextField required id="form-subject" label="Θέμα" variant="outlined"
                      {...register("Subject", { required: true, minLength: 4 })}
                      value={subject} onChange={e => setSubject(e.target.value)} margin="dense" size="small"/>
                {errors.Subject && <p className='form-error'>Απαραίτητο πεδίο</p>}
            </div>
            <div className="flex-row">
              <div className="txt-n-error">
                  <FormControl fullWidth margin="dense" size="small">
                    <InputLabel id="form-type">Είδος</InputLabel>
                    <Select labelId="form-type" id="form-select" label="Είδος" variant="outlined"                          
                          value={type} onChange={e => setType(e.target.value)}>
                          <MenuItem value={'Στέγη'} dense>Στέγη</MenuItem>
                          <MenuItem value={'Έδαφος'} dense>Έδαφος</MenuItem>
                          <MenuItem value={'Δώμα'} dense>Δώμα</MenuItem>
                          <MenuItem value={'Στέγαστρο'} dense>Στέγαστρο</MenuItem>
                          <MenuItem value={'Άλλο'} dense>Άλλο</MenuItem>   
                    </Select>
                  </FormControl>
              </div>
              <div className="separator"></div>
              <div className="txt-n-error">
                  <TextField fullWidth id="form-kwh" label="Eτήσια κατανάλωση (kWh)" variant="outlined"
                         {...register("kwh", { required: false, minLength: 2, pattern: /^[0-9]*$/ })}
                         value={kwh} onChange={e => setKwh(e.target.value)} margin="dense" size="small"/>
                  {errors.kwh && <p className='form-error'>Αριθμητικό πεδίο</p>}
              </div>
              <div className="separator"></div>
              <div className="txt-n-error">
                  <TextField fullWidth id="form-m2" label="Διαθέσιμη επιφάνεια (m²)" variant="outlined"
                         {...register("m2", { required: false, pattern: /^[0-9]*$/ })}
                         value={m2} onChange={e => setM2(e.target.value)} margin="dense" size="small"/>
                  {errors.m2 && <p className='form-error'>Αριθμητικό πεδίο</p>}
              </div>                     
            </div>
            <div className="flex-row">           
              <div className="txt-n-error">
                  <TextField fullWidth id="form-address" label="Διεύθυνση" variant="outlined"
                         {...register("Αddress", { required: false, })}
                         value={address} onChange={e => setAddress(e.target.value)} margin="none" size="small"/>
              </div>
              <div className="separator"></div>
              <div className="txt-n-error">
                  <TextField fullWidth id="form-tel" label="Τηλέφωνο" variant="outlined" required
                         {...register("Tel", { required: true, pattern: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ })}
                         value={tel} onChange={e => setTel(e.target.value)} margin="dense" size="small"/>
                  {errors.Tel && <p className='form-error'>Συμπληρώστε το τηλέφωνο σας</p>}
              </div>
            </div>
            <div className="txt-n-error">
              <TextField fullWidth id="form-message" label="Μήνυμα" variant="outlined" multiline rows={2}
                      {...register("Message")}
                      value={message} onChange={e => setMessage(e.target.value)}/>
            </div>
            <Button type="submit" size="small" sx={{ backgroundColor: primary, color: '#ffffff', textTransform: 'unset', marginTop:'10px',
                                                     '&:hover':{backgroundColor: secondary}}}>
              {btntext}
            </Button>
            <p>{status}</p>
          </Box>
      </Modal>
    </Box>
  );
}

export {ContactFormPVModal}
