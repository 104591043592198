import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import EastIcon from '@mui/icons-material/East';
import Tooltip from '@mui/material/Tooltip';
import AppDeck from './functional/AppDeck';
import Intro from './structure/Intro';
import Section from './structure/section';
import AppTab from './structure/appTab';
import Footer from './Footer';
import { useInView } from 'react-intersection-observer';
import { sectionData } from './text/sectionText';
// import { ContactFormModal } from './functional/ContactForm';
// import { ContactFormPVModal } from './functional/ContactFormPV';
// import { useParams, useNavigate } from "react-router-dom"

import logo from './img/NE-logo.png';
import bg0 from './img/bg0.jpg';
import bg1 from './img/bg2.jpg';
// import bg2 from './img/bg3.jpg';
import bg3 from './img/bg3.jpg';
import bg4 from './img/bg5.jpg';
import bg5 from './img/bg6.jpg';


const mainCl = "css-tx3tai-MuiButtonBase-root-MuiTab-root"

function TabPanel(props) {
  const { children, value, id, index, ...other } = props;
  const styles = { scrollSnapAlign: 'start', height:'100vh', width:'100%' }
  // const [tabref, inView] = useInView({ threshold: 0.1 });

  return (
    <div
      id={`${id}`}
      // className={inView ? "panel active" : "panel"}
      className={"panel"}
      style={styles}
      {...other}
    >
      {/*{value === index && (*/}
        {/*<Box sx={{ display:'flex', justifyContent:'center', width:'100%', height:'100%' }} ref={tabref}>*/}
        <Box sx={{ display:'flex', justifyContent:'center', width:'100%', height:'100%' }}>
          {children}
        </Box>
      {/*)}*/}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Sections(props) {
  const theme = useTheme();
  const [value, setValue] = React.useState(false);
  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };
  const clearSelected = () => {
    setValue(false)
  };
  // const { active_tab } = useParams();
  // const navigate = useNavigate();
  
  const [ref0, inView0] = useInView({ threshold: 0.1 });
  const [ref1, inView1] = useInView({ threshold: 0.6 });
  const [ref2, inView2] = useInView({ threshold: 0.6 });
  const [ref3, inView3] = useInView({ threshold: 0.6 });
  const [ref4, inView4] = useInView({ threshold: 0.6 });
  const [ref5, inView5] = useInView({ threshold: 0.6 });
  const [ref6, inView6] = useInView({ threshold: 0.6 });

  // const activeTab = () => {
  //   if      (inView0) { document.location.replace('/#'+sectionData[0].link); return 0; }
  //   else if (inView1) { document.location.replace('/#'+sectionData[1].link); return 1; }
  //   else if (inView2) { document.location.replace('/#'+sectionData[2].link); return 2; }
  //   else if (inView3) { document.location.replace('/#'+sectionData[3].link); return 3; }
  //   else if (inView4) { document.location.replace('/#'+sectionData[4].link); return 4; }
  //   else if (inView5) { document.location.replace('/#'+sectionData[5].link); return 5; }
  //   else if (inView6) { document.location.replace('/#'+sectionData[6].link); return 6; }
  //   else if (inView7) { document.location.replace('/#contact');              return 7; }
  // };

  return (
    <Box sx={{ bgcolor: 'background.paper', minHeight:'100%' }} >
      <Box display={{xs:'none',sm:'block'}} 
           sx={{scrollSnapType: 'y mandatory', flexDirection:'column', height:'100vh', overflowY: 'scroll',
                scrollBehavior:'smooth' }}>
        <TabPanel value={value} index={0}>
          <div ref={ref0} className="ref-container">
              <Intro bg={bg0} head={logo} btn={'ΜΑΘΕΤΕ ΠΕΡΙΣΣΟΤΕΡΑ'} linkTo={`/#`+sectionData[1].link} 
                    text={sectionData[0].mainText} subtext={sectionData[0].subText} inView={inView0}  />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} id={sectionData[1].link}>
          <div ref={ref1} className="ref-container">
              <Section bg={bg1} btn={<EastIcon/>} height={'100%'} linkTo={'/net-metering'} form={'none'}
                      head={sectionData[1].head} text={sectionData[1].mainText} color={theme.palette.primary.main} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} id={sectionData[2].link}>
          <div ref={ref2} className="ref-container">
              <AppDeck />
              <AppTab />
          </div>
        </TabPanel>
        <TabPanel value={value} index={3} id={sectionData[3].link}>
          <div ref={ref3} className="ref-container">
              <Section bg={bg3} btn={<EastIcon/>} height={'100%'}  linkTo={'/saving'} form={'none'}
                      head={sectionData[3].head} text={sectionData[3].mainText} color={theme.palette.primary.main} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={4} id={sectionData[4].link}>
          <div ref={ref4} className="ref-container">
              <Section bg={bg4} haveBtn={'none'} height={'100%'}
                      head={sectionData[4].head} text={sectionData[4].mainText} color={theme.palette.primary.main} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={5} id={sectionData[5].link}>
          <div ref={ref5} className="ref-container">
              <Section bg={bg5} haveBtn={'none'} height={'100%'}
                      head={sectionData[5].head} text={sectionData[5].mainText} color={theme.palette.primary.main} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={6} id={`Contact`}>
          <div ref={ref6} className="ref-container">
              <Footer />
          </div>
        </TabPanel>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={clearSelected}
          aria-label="vertical tabs"
          scrollButtons={true}
          visibleScrollbar
          sx={{ borderLeft: 0, borderColor: 'divider', justifySelf:'end', position: 'fixed', height:'100%', top:0, right:0,
                display: {xs:'none', md:'flex', lg:'flex'}, width: {xs:'56px', md:'70px', lg:'90px'},
            }}
        >
          <Tab component="a" href="/"                        className={inView0 ? mainCl + " Mui-selected" : mainCl }
              icon={<Tooltip arrow placement="left" title={sectionData[0].tooltip} >
                      <CircleIcon sx={{ fontSize: {xs:'0.8rem', md:'1rem', lg:'1rem'}}} />
                    </Tooltip>} />
          <Tab component="a" href={`/#`+sectionData[1].link} className={inView1 ? mainCl + " Mui-selected" : mainCl }
              icon={<Tooltip arrow placement="left" title={sectionData[1].tooltip} >
                      <CircleIcon sx={{ fontSize: {xs:'0.8rem', md:'1rem', lg:'1rem'}}} />
                    </Tooltip>} />
          <Tab component="a" href={`/#`+sectionData[2].link} className={inView2 ? mainCl + " Mui-selected" : mainCl }
              icon={<Tooltip arrow placement="left" title={sectionData[2].tooltip} >
                      <CircleIcon sx={{ fontSize: {xs:'0.8rem', md:'1rem', lg:'1rem'}}} />
                    </Tooltip>} />
          <Tab component="a" href={`/#`+sectionData[3].link} className={inView3 ? mainCl + " Mui-selected" : mainCl }
              icon={<Tooltip arrow placement="left" title={sectionData[3].tooltip} >
                      <CircleIcon sx={{ fontSize: {xs:'0.8rem', md:'1rem', lg:'1rem'}}} />
                    </Tooltip>} />
          <Tab component="a" href={`/#`+sectionData[4].link} className={inView4 ? mainCl + " Mui-selected" : mainCl }
              icon={<Tooltip arrow placement="left" title={sectionData[4].tooltip} >
                      <CircleIcon sx={{ fontSize: {xs:'0.8rem', md:'1rem', lg:'1rem'}}} />
                    </Tooltip>} />
          <Tab component="a" href={`/#`+sectionData[5].link} className={inView5 ? mainCl + " Mui-selected" : mainCl }
              icon={<Tooltip arrow placement="left" title={sectionData[5].tooltip} >
                      <CircleIcon sx={{ fontSize: {xs:'0.8rem', md:'1rem', lg:'1rem'}}} />
                    </Tooltip>} />
          <Tab component="a" href={`/#Contact`} className={inView6 ? mainCl + " Mui-selected" : mainCl }
              icon={<Tooltip arrow placement="left" title={`Επικοινωνία`} >
                      <CircleIcon sx={{ fontSize: {xs:'0.8rem', md:'1rem', lg:'1rem'}}} />
                    </Tooltip>} />
        </Tabs>
      </Box>
      <Box display={{xs:'block',sm:'none'}} 
           sx={{scrollSnapType: 'y mandatory', flexDirection:'column', height:'100vh', overflowY: 'scroll',
                scrollBehavior:'smooth' }}>
        <div className="mob-section">
              <Intro bg={bg0} head={logo} btn={'ΜΑΘΕΤΕ ΠΕΡΙΣΣΟΤΕΡΑ'} linkTo={`/#`+sectionData[1].moblink}
                    text={sectionData[0].mainText} subtext={sectionData[0].subText} inView={true}  />
        </div>
        <div className="mob-section" id={sectionData[1].moblink}>
              <Section bg={bg1} btn={<EastIcon/>} height={'100%'} linkTo={'/net-metering'} form={'none'}
                      head={sectionData[1].head} text={sectionData[1].mainText} color={theme.palette.primary.main} />
        </div>
        <div className="mob-section" id={sectionData[2].moblink}>
              <AppDeck />
        </div>
        <div className="mob-section" id={sectionData[3].moblink}>
              <Section bg={bg3} btn={<EastIcon/>} height={'100%'}  linkTo={'/saving'} form={'none'}
                      head={sectionData[3].head} text={sectionData[3].mainText} color={theme.palette.primary.main} />
        </div>
        <div className="mob-section" id={sectionData[4].moblink}>
              <Section bg={bg4} haveBtn={'none'} height={'100%'}
                      head={sectionData[4].head} text={sectionData[4].mainText} color={theme.palette.primary.main} />
        </div>
        <div className="mob-section" id={sectionData[5].moblink}>
              <Section bg={bg5} haveBtn={'none'} height={'100%'}
                      head={sectionData[5].head} text={sectionData[5].mainText} color={theme.palette.primary.main} />
        </div>
        <div className="mob-section" id={`contact`}>
              <Footer />
        </div>    
      </Box>
    </Box>
  );
}
