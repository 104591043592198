import React from "react";
import GoogleMapReact from 'google-map-react';
import Avatar from '@mui/material/Avatar';
import logo from '../img/NE-logo-plain.png';

export default function NovoMap(){
  const defaultProps = {
    center: {
      lat: 37.9064,
      lng: 23.7269
    },
    zoom: 16
  };

  return (
    <div style={{ height: '200px', width: '70%' }} className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyBs8d_YQHJXMs6WbsCj7F9_tIYRwWOlceQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Avatar lat={37.9064}
                lng={23.7269}
                text="Novo Energy"
                style={{ backgroundColor: 'transparent', backgroundSize:'contain'}}
        >
            <img src={logo} alt="logo" style={{width:'70%'}} />
        </Avatar>
      </GoogleMapReact>
    </div>
  );
}
