import React from 'react';
import Box from '@mui/material/Box';
import Application from './application';

import { appsData } from '../text/appsText';
import bg4 from '../img/bg4.jpg';

export default function AppTab() {
    return (
        <Box sx={{ width:'100%', height:'100%', display: { xs: 'none', sm: 'none', md:'flex' }, flexDirection:'row', justifyContent:'center',
                   alignItems:'center', flexWrap:{xs:'wrap', md:'nowrap'},
                   backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3)), url(${bg4})`,
                   backgroundSize:'cover', backgroundRepeat:'no-repeat',
                }}>
            <Application title={appsData[0].label} imgfile={appsData[0].imgPath} imgtitle={appsData[0].label} text={appsData[0].text} bgPosition={'center'} />
            <Application title={appsData[1].label} imgfile={appsData[1].imgPath} imgtitle={appsData[1].label} text={appsData[1].text} bgPosition={'center'}/>
            <Application title={appsData[2].label} imgfile={appsData[2].imgPath} imgtitle={appsData[2].label} text={appsData[2].text} bgPosition={'63% 25%'}/>
            <Application title={appsData[3].label} imgfile={appsData[3].imgPath} imgtitle={appsData[3].label} text={appsData[3].text} bgPosition={'center'}/>
            {/*<Application title={appsData[4].label} imgfile={appsData[4].imgPath} imgtitle={appsData[4].label} text={appsData[4].text} />*/}
        </Box>
    )
}
